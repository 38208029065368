
import { defineComponent, onMounted, ref } from 'vue'
import axios from '@/http'

interface IAllStat {
	lotCount: number
	productCount: number
	variationCount: number
	storageQhCount: number
	storageQhTransferCount: number
	storageQhCollectingCount: number
	storageQhPhotozoneCount: number
	storageQhDeliveryCount: number
	storageQhShippingCount: number
	storageQhPhysicalCount: number
	orderCreatedCount: number
	orderConfirmedCount: number
	orderPaidCount: number
	orderCollectingCount: number
	orderCollectedCount: number
	orderShippedCount: number
	orderReceivedCount: number
}

export default defineComponent({
	name: 'DashboardPage',

	setup() {
		const stats = ref<IAllStat | null>(null)

		onMounted(async () => {
			stats.value = (await axios.get('/internal/get-all-stats', { cache: true })).data
		})

		return {
			stats
		}
	}
})
